import { Checkbox, Input, PreloaderCircular } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import { useEffect, useState } from 'react';
import './styles.css';
import { IUser, useFetchUsers } from '@shared/hooks/useFetchUsers.ts';
import { IInvitedParticipants } from '@entities/segments-tab/index.tsx';
import { useFindUserByName } from '@pages/event/api/useFetchSearchUser.ts';

interface CompaniesStructureProps {
  invitedParticipants: IInvitedParticipants[];
  setSelectedParticipants: (users: number[]) => void;
  selectedParticipants: number[];
  setParticipantsDelete: React.Dispatch<React.SetStateAction<IInvitedParticipants[]>>;
  participantsDelete: IInvitedParticipants[];
}

interface Participant {
  id: number;
  firstName: string;
  lastName: string;
  avatar: null | string;
}

const CompaniesStructure: React.FC<CompaniesStructureProps> = ({
  setSelectedParticipants,
  invitedParticipants = [],
  selectedParticipants,
  setParticipantsDelete,
  participantsDelete,
}) => {
  const { userData, fetching: fetchingAllUsers } = useFetchUsers();

  const [inputText, setInputText] = useState('');
  const [displayedUsers, setDisplayedUsers] = useState<IUser[]>([]);
  const [debouncedInputText, setDebouncedInputText] = useState('');
  const { users: searchedUsers, fetching: fetchingSearch } = useFindUserByName(debouncedInputText);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedInputText(inputText.toLowerCase());
    }, 200);

    return () => {
      clearTimeout(handler);
    };
  }, [inputText]);

  useEffect(() => {
    if (!debouncedInputText.trim()) {
      setDisplayedUsers(userData?.data || []);
    } else {
      const filteredUsers = userData?.data.filter((user) => {
        const fullName = `${user.firstName?.toLowerCase().trim()} ${user.lastName?.toLowerCase().trim()}`;
        const reversedName = `${user.lastName?.toLowerCase().trim()} ${user.firstName?.toLowerCase().trim()}`;
        return fullName.includes(debouncedInputText) || reversedName.includes(debouncedInputText);
      });
      setDisplayedUsers(filteredUsers || []);
    }
  }, [userData, searchedUsers, debouncedInputText]);

  const handleCheck = async (participant: Participant) => {
    const invitedParticipant = invitedParticipants.find((el) => el.userId === participant.id);
    if (invitedParticipant) {
      if (participantsDelete.find((el) => el.userId === participant.id)) {
        setParticipantsDelete(participantsDelete.filter((elem) => elem.userId !== participant.id));
      } else {
        setParticipantsDelete([...participantsDelete, invitedParticipant]);
      }
    } else {
      if (selectedParticipants.includes(participant.id)) {
        setSelectedParticipants(selectedParticipants.filter((el) => el !== participant.id));
      } else {
        setSelectedParticipants([...selectedParticipants, participant.id]);
      }
    }
  };

  const statusAllChecked = userData && userData.data.length === selectedParticipants.length;

  const handleAllCheck = () => {
    if (statusAllChecked) {
      setSelectedParticipants([]);
      setParticipantsDelete([]);
    } else {
      const newCheckedUsers = userData?.data.map((participant: Participant) => participant.id) || [];
      setSelectedParticipants(newCheckedUsers);
    }
  };

  const handleInput = (text: string) => {
    setInputText(text);
  };

  const selectedParticipantsCount = [
    ...new Set([
      ...invitedParticipants.filter((id) => !participantsDelete.includes(id)).map((el) => el.userId),
      ...selectedParticipants,
    ]),
  ];

  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.inputWithSelectedParticipant)}>
        <div {...stylex.props(styles.input)}>
          <Input
            type="search"
            status="default"
            placeholder="Поиск"
            value={inputText}
            size="small"
            settings={{ width: '546px' }}
            changeFunction={handleInput}
          />
        </div>
        <div {...stylex.props(styles.participant)}>
          Количество выбранных участников: {selectedParticipantsCount.length}
        </div>
      </div>
      <div {...stylex.props(styles.blockAllList)}>
        <div {...stylex.props(styles.checkboxWithName)}>
          <Checkbox onChange={handleAllCheck} size={'small'} isChecked={statusAllChecked} isIndeterminate={false} />
          <div {...stylex.props(styles.participant)}>Выбрать всех сотрудников</div>
        </div>

        <div className="custom-scroll">
          <div {...stylex.props(styles.allList)}>
            {fetchingSearch && inputText && (
              <p style={{ margin: '0 auto' }}>
                <PreloaderCircular />
              </p>
            )}
            {!fetchingAllUsers &&
              !fetchingSearch &&
              displayedUsers.map((participant, index) => (
                <div key={index} {...stylex.props(styles.checkboxWithName)} onClick={() => handleCheck(participant)}>
                  <Checkbox
                    onChange={() => {}}
                    size={'small'}
                    isChecked={
                      invitedParticipants.find((el) => el.userId === participant.id)
                        ? !participantsDelete.find((elem) => elem.userId === participant.id)
                        : selectedParticipants.includes(participant.id)
                    }
                  />
                  <div {...stylex.props(styles.participant)}>
                    <p>
                      {participant.firstName} {participant.lastName}
                    </p>
                    <p {...stylex.props(styles.position)}>{participant.position}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompaniesStructure;
